<template>
    <div>
        <div>
            <!-- 框架的heard -->
            <div>
                <el-tabs type="border-card">
                    <el-tab-pane label="文本校对">
                        <el-form ref="uploadForm">
                          <el-form-item label="" style="display:flex;justify-content:end"> 
                              <el-button type="primary" @click="handleUpload">上传文件</el-button>
                              <!-- <el-button type="success" @click="downloadAsPDF">下载为PDF</el-button> -->
                              <el-button type="success" @click="downloadAsWord">下载为Word</el-button>
                          </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="使用说明">
                        <el-tag>标签一</el-tag>
                        <el-tag type="success">标签二</el-tag>
                        <el-tag type="info">标签三</el-tag>
                        <el-tag type="warning">标签四</el-tag>
                        <el-tag type="danger">标签五</el-tag>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <!-- 框架的body -->
            <div style="margin-top: -40px;">
                <el-row>
                    <el-col :span="14" style="margin-top:20px;margin-left:-20px;width:65%">
                        <div style="margin: 20px">
                          <el-card class="box-card">
                      <el-descriptions title="正文预览" direction="vertical" :column="4" border>
                        <el-descriptions-item>
                          <!-- Quill编辑器容器 -->
                          <div ref="quillEditor" style="border: 1px solid #ccc; padding: 5px; margin-top: 5px;"></div>
                        </el-descriptions-item>
                      </el-descriptions>
                    </el-card>
                  </div>
                    </el-col>
                    <el-col :span="8" style="margin-top:20px;margin-left:-25px;height:100%,width:100%">
                        <div style="margin: 20px;height:100%">
                            <el-card class="box-card" style="height:100%">
                              <el-tabs v-model="activeName" @tab-click="handleClick">
                                    <el-tab-pane label="文字校对" name="first">
                                      <ul class="infinite-list" v-infinite-scroll="load" style="overflow:auto">
                                      <div v-for="(alert, index) in alerts" :key="index">
                                          <el-descriptions direction="vertical" :column="4" border>
                                            <el-descriptions-item v-if="alert.sourceText" label="错误文本"><span class="blue-text">{{ alert.sourceText }}</span></el-descriptions-item>
                                            <el-descriptions-item v-if="alert.replaceText" label="双击修改" :span="2" style="width:150px">
                                              <span class="blue-text1" @click="replaceText(alert, index)">{{ alert.replaceText }}</span>
                                            </el-descriptions-item>
                                          </el-descriptions>
                                        </div>
                                        </ul>
                                      </el-tab-pane>
                                      <el-tab-pane label="ai改写" name="second">
                                        <ul class="infinite-list" v-infinite-scroll="load" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="10" style="overflow:auto">
                                                <div v-for="(alert, index) in filteredAlerts" :key="index">
                                                  <el-descriptions direction="vertical" :column="4" border>
                                                    <el-descriptions-item label="错误文本" width:20%>
                                                      <span class="blue-text">{{ alert.sourceText }}</span>
                                                    </el-descriptions-item>
                                                    <el-descriptions-item label="AI 生成文本"><span class="blue-text">{{ alert.aiText }}</span></el-descriptions-item>
                                                  </el-descriptions>
                                                </div>
                                              </ul>
                                      </el-tab-pane>
                                      <el-tab-pane label="修改建议" name="third">
                                        <ul class="infinite-list" v-infinite-scroll="load" infinite-scroll-disabled="infiniteScrollDisabled" infinite-scroll-distance="10" style="overflow:auto">
                                                <div v-for="(alert, index) in alerts" :key="index">
                                                  <el-descriptions direction="vertical" :column="4" border>
                                                    <el-descriptions-item v-if="alert.alertMessage" label="错误提示">{{ alert.alertMessage }}</el-descriptions-item>      
                                                  </el-descriptions>
                                                </div>
                                              </ul>
                                      </el-tab-pane>
                                  </el-tabs>
                            </el-card>
                        </div>
                    </el-col>
                </el-row>
                <div>
                      <el-card class="box-card" >
                        {{dfdfdfd}}
                                <div v-if="wordErrors || punctuationErrors">
                                  <el-descriptions  direction="vertical" :column="4" border>
                                    <el-descriptions-item label="词语问题">{{ wordErrors }}</el-descriptions-item>
                                    <el-descriptions-item label="标点问题"><span class="blue-text">{{ punctuationErrors }}</span></el-descriptions-item>
                                    <el-descriptions-item label="语序问题" :span="2"><span class="blue-text1">{{yuxu }}</span></el-descriptions-item>
                                    <el-descriptions-item label="语法问题" :span="2"><span class="blue-text1">{{ yufa }}</span></el-descriptions-item>


                                    <el-descriptions-item label="敏感词错误">{{ minganc }}</el-descriptions-item>
                                    <el-descriptions-item label="日期错误"><span class="blue-text">{{ rq }}</span></el-descriptions-item>
                                    <el-descriptions-item label="金额错误" :span="2"><span class="blue-text1">{{je }}</span></el-descriptions-item>
                                    <el-descriptions-item label="重复定义" :span="2"><span class="blue-text1">{{ cfdy }}</span></el-descriptions-item>

                                    <el-descriptions-item label="序标错误">{{ xbcw }}</el-descriptions-item>
                                    <el-descriptions-item label="领导人职位
称呼错误"><span class="blue-text">{{ ldrcw }}</span></el-descriptions-item>
                                    <el-descriptions-item label="行政区划变
更" :span="2"><span class="blue-text1">{{xzqbh }}</span></el-descriptions-item>
                                  
                                  </el-descriptions>
                                </div>
                      </el-card>
                    </div>
                    <div>
                       
                    </div>
                    
            </div>
        </div>
    </div>
</template>

<script>

import { jsPDF } from 'jspdf';
import { Packer, Paragraph, Document,Run } from 'docx';
import { concat } from 'sockjs-client/lib/transport-list';
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill';
function htmlToParagraphs(html) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(html, 'text/html');
  const paragraphs = doc.querySelectorAll('p');
  return Array.from(paragraphs).map(p => {
    const run = new Run({
      text: p.textContent,
      // 在这里可以添加更多的样式属性
    });
    return new Paragraph({ children: [run] });
  });
}
export default {

  name: 'EditorComponent',
    data() {
      return {
      dfdfdfd: '错误统计',
      count: 0,
      dfdf: '内容建议',
      textarea: '',
      file: null,
      fileName: '无标题', // 用于存储文件名称
      alerts: [],
      serverData: {},
      checkLimitInfo: {},
      texts: [],
      dfdfd: '正文预览',
      wordErrors: 0,
      punctuationErrors: 0,
      yuxu: 0,
      activeName: 'third',
      yufa: 0,
      minganc: 0,
      rq: 0,
      je: 0,
      cfdy: 0,
      xbcw: 0,
      ldrcw: 0,
      xzqbh: 0,
      modifiedTexts: [],
      replacedIndices: [], // 存储已替换的索引
      content: ''
      };
    },
     computed: {
      filteredAlerts() {
      // 过滤出 errorType 为 5 的 alerts
      return this.alerts.filter(alert => alert.errorType === 5);
    },
    textsJoined: {
      get() {
      // 将texts数组中的文本转换为HTML段落，并添加首行缩进的样式
      return this.texts.map(text => `<p style="text-indent: 2em;">${text.trim()}</p>`).join('');
    },
    set(value) {
      // 将HTML字符串转换回纯文本数组
      const parser = new DOMParser();
      const doc = parser.parseFromString(value, 'text/html');
      const paragraphs = doc.querySelectorAll('p');
      this.texts = Array.from(paragraphs).map(p => p.textContent || '');
    }
    },
    hasErrors() {
      return this.wordErrors > 0 || this.punctuationErrors > 0 || this.yuxu > 0 || this.yufa > 0 || this.minganc > 0 || this.rq > 0 || this.je > 0 || this.cfdy > 0 || this.xbcw > 0 || this.ldrcw > 0 || this.xzqbh > 0;
    }
  },
  mounted() {
    this.initQuillEditor();
    this.initEditor();
    console.log(window.wangEditor); // 检查 window.wangEditor 是否正确导入
  },
    
    methods: {

initQuillEditor() {
  // 确保DOM元素存在
  if (this.$refs.quillEditor) {
    this.quillEditor = new Quill(this.$refs.quillEditor, {
      theme: 'snow', // 使用默认的snow主题
      modules: {
        toolbar: [
        ['bold', 'italic', 'underline', 'strike'],    // 加粗，斜体，下划线，删除线
        ['blockquote', 'code-block'],                // 引用，代码块
        [{'header': 1}, {'header': 2}],              // 标题
        [{'list': 'ordered'}, {'list': 'bullet'}],    // 列表
        [{'script': 'sub'}, {'script': 'super'}],     // 上下标
        [{'indent': '-1'}, {'indent': '+1'}],          // 缩进
        [{'direction': 'rtl'}],                       // 文本方向
        [{'size': ['small', false, 'large', 'huge']}], // 字体大小
        [{'header': [1, 2, 3, 4, 5, 6, false]}],       // 标题等级
        [{'color': []}, {'background': []}],          // 文字颜色，背景颜色
        [{'font': []}],                                // 字体
        [{'align': []}],                               // 文本对齐方式
        ['clean']                                      // 清除格式
      ]
    },
    placeholder: "请输入内容...",
  });

    // 将textsJoined的内容设置到Quill编辑器中
    if (this.textsJoined) {
      this.setTextsToQuill();
    }

    // 监听编辑器内容变化
    this.quillEditor.on('text-change', (delta, oldDelta, source) => {
      if (source === Quill.sources.USER) {
        // 当用户修改内容时，更新 textsJoined
        this.textsJoined = this.quillEditor.root.innerHTML; // 更新数据
      }
    });
  }
},
setTextsToQuill() {
  // 将textsJoined的内容设置到Quill编辑器中
  this.quillEditor.clipboard.dangerouslyPasteHTML(this.textsJoined, Quill.sources.USER);
},

      updateTextsJoined() {
      const editedText = this.$refs.editableDiv.innerHTML;
      this.textsJoined = editedText;
      },
      initEditor() {
      const { Editor } = window.wangEditor;

      const editor = new Editor('#editor');

      // 配置编辑器
      editor.config.placeholder = '请输入内容...';

      // 配置 onchange 回调函数，将数据同步到 vue data 中
      editor.config.onchange = (newHtml) => {
        this.content = newHtml;
      };

      // 创建编辑器
      editor.create();

      // 初始内容
      editor.txt.html('<p>初始内容</p>');
    },
      handleClick(tab, event) {
        console.log(tab, event);
      },
      load () {
        this.count += 2
      },
      handleChange(file) {
        this.file = file.raw; // 获取原始文件对象
      },
      handleUpload() {
    const input = document.createElement('input');
    input.type = 'file';
    input.onchange = (e) => {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append('file', file);

      // 发送请求到服务器端上传文件
      this.$axios.post('/xzm1/textCheck', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }).then(response => {
        // 处理上传成功的响应
        console.log('上传成功响应数据:', response.data);
        this.serverData = response.data;
        console.log('serverData:', this.serverData);

        // 提取 thirdPartyResponse
        let thirdPartyResponse = response.data.thirdPartyResponse;
        if (thirdPartyResponse) {
          // 扁平化 alerts
          this.alerts = this.flattenAlerts(thirdPartyResponse.alerts || []);
          console.log('alerts:', this.alerts);
        }
        // 提取 texts
        const requestEntity = response.data.requestEntity;
        if (requestEntity) {
          const parsedRequestEntity = JSON.parse(requestEntity.body);
          this.texts = parsedRequestEntity.texts || [];
          this.modifiedTexts = [...this.texts]; // ())
          // console.log('texts:', this.texts);
          // console.log('modifiedTexts:', this.alerts);
          if(this.alerts.length>0){
           for(var i = 0;i<this.alerts.length;i++){
             this.replaceText1(this.alerts[i],i);
           }
        }
        }
        // const fileContentHtml = this.modifiedTexts;
        // this.textsJoined1 = fileContentHtml;
        // if (this.quillEditor) {
             this.setTextsToQuill(); // 更新 Quill 编辑器的内容
        // }
        // 遍历alerts 数组，如果就显示ai文本
        this.alerts.flat().forEach(item => {
        if (item.errorType === 5) { // 词语问题
          this.wordErrors++;
        }
      });
        if (thirdPartyResponse === '') {
          this.$message({ message: '请先上传文件', type: 'warning' }); // 显示上传成功的消息
        }

        // 统计错误类型
        this.countErrorsByType();
      }).catch(error => {
        // 处理上传失败的情况
        console.error('上传失败:', error);
      });
    };
    input.click();
  },

  // 扁平化 alerts 方法
  flattenAlerts(alerts) {
    return [].concat(...alerts);
  },
         // 替换文本的方法
  replaceText(alert, index) {
  // 获取错误文字的位置
  const start = alert.start;
  const end = alert.end;
  const replaceText = alert.replaceText;
  const sourceText = alert.sourceText;
  console.log("开始位置：", start, "结束位置：", end + 1, "替换文字：", replaceText);
  // 更新 texts
console.log(this.texts)
  // for(var i= 0;i<this.texts.length;i++){
    
  //    if (this.texts[i].length >= end) {
  //     // 获取需要被替换的文本片段
  //     const errorText = this.texts[i].slice(start, end + 1);
  //     console.log('需要被替换的文本片段:', errorText);
  //     // 检查该文本片段是否与sourceText相等
  //     console.log('12345612121:', sourceText);
  //     if (errorText === sourceText) {
  //       console.log('123456:', errorText);
  //       // 替换错误文字，并用<span>标签包裹替换后的文本，设置颜色为红色
  //       return this.texts[i].slice(0, start) + `<span style="color: red;">${replaceText}</span>` + this.texts[i].slice(end + 1);
  //     }
  //   }
  //   // 如果不匹配或长度不足，返回原始文本
  //   return this.texts[i];
  // }
  this.texts = this.texts.map(text => {
    
    // 检查文本长度是否足够
    if (text.length >= end) {
      // 获取需要被替换的文本片段
      const errorText = text.slice(start, end + 1);
      console.log('需要被替换的文本片段:', errorText);
      // 检查该文本片段是否与sourceText相等
      if (errorText === sourceText) {
        // 替换错误文字，并用<span>标签包裹替换后的文本，设置颜色为红色
        return text.slice(0, start) + `<span style="color: red;">${replaceText}</span>` + text.slice(end + 1);
      }
    }
    // 如果不匹配或长度不足，返回原始文本
    return text;
  });

  // 更新 modifiedTexts
  this.modifiedTexts = [...this.texts];

  // 更新 alerts
  this.alerts[index].sourceText = replaceText;

  // 从alerts数组中移除这条数据
  this.alerts.splice(index, 1);
  
  console.log('替换后的 texts:', this.texts);
},
// 页面打开错误文本标红
replaceText1(alert, index) {
  // 获取错误文字的位置
  const start = alert.start;
  const end = alert.end;
  const replaceText = alert.replaceText;
  const sourceText = alert.sourceText;
  console.log("开始位置：", start, "结束位置：", end + 1, "替换文字：", replaceText);
  // 更新 texts
console.log(this.texts)
  // for(var i= 0;i<this.texts.length;i++){
    
  //    if (this.texts[i].length >= end) {
  //     // 获取需要被替换的文本片段
  //     const errorText = this.texts[i].slice(start, end + 1);
  //     console.log('需要被替换的文本片段:', errorText);
  //     // 检查该文本片段是否与sourceText相等
  //     console.log('12345612121:', sourceText);
  //     if (errorText === sourceText) {
  //       console.log('123456:', errorText);
  //       // 替换错误文字，并用<span>标签包裹替换后的文本，设置颜色为红色
  //       return this.texts[i].slice(0, start) + `<span style="color: red;">${replaceText}</span>` + this.texts[i].slice(end + 1);
  //     }
  //   }
  //   // 如果不匹配或长度不足，返回原始文本
  //   return this.texts[i];
  // }
  this.texts = this.texts.map(text => {
    
    // 检查文本长度是否足够
    if (text.length >= end) {
      // 获取需要被替换的文本片段
      const errorText = text.slice(start, end + 1);
      console.log('需要被替换的文本片段:', errorText);
      // 检查该文本片段是否与sourceText相等
      if (errorText === sourceText) {
        // 替换错误文字，并用<span>标签包裹替换后的文本，设置颜色为红色
        return text.slice(0, start) + `<span style="color: red;">${replaceText}</span>` + text.slice(end + 1);
      }
    }
    // 如果不匹配或长度不足，返回原始文本
    return text;
  });

//   // 更新 modifiedTexts
//   this.modifiedTexts = [...this.texts];

//   // 更新 alerts
//   this.alerts[index].sourceText = replaceText;

//   // 从alerts数组中移除这条数据
//   this.alerts.splice(index, 1);
  
  console.log('替换后的 texts:', this.texts);
},
    downloadAsPDF() {
      if(this.texts == ""){
        this.$message({ message: '请先上传文件', type: 'warning' }); 
        return;
      }
      // 创建一个新的 jsPDF 实例
      const doc = new jsPDF();

      // 添加文本内容
      doc.text(this.texts.join('\n'), 10, 10);

      // 生成 PDF 文件并下载
      doc.save(this.fileName.replace(/\.[^/.]+$/, '') + '.pdf');
    },
     downloadAsWord() {
      if (this.textsJoined === "") {
        this.$message({ message: '请先上传文件', type: 'warning' });
        return;
      }
      try {
        // 将HTML转换为Paragraph对象
        const paragraphs = htmlToParagraphs(this.textsJoined);

        // 创建一个新的 Document 实例
        const doc = new Document({
          creator: 'Your Name',
          title: 'Document Title',
          description: 'Document Description',
          sections: [{
            properties: {},
            children: paragraphs
          }]
        });

        // 生成 Word 文件
        Packer.toBlob(doc).then(blob => {
          const url = URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.href = url;
          a.download = `${this.fileName}.docx`;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          URL.revokeObjectURL(url);
        }).catch(error => {
          console.error('Error generating Word document:', error);
        });
      } catch (error) {
        console.error('Error creating Document instance:', error);
      }
    },
          countErrorsByType() {
      // 初始化计数器
      this.wordErrors = 0;
      this.punctuationErrors = 0;

      // 遍历 alerts 数组并统计错误类型
      this.alerts.flat().forEach(item => {
        if (item.errorType === 1) { // 词语问题
          this.wordErrors++;
        } else if (item.errorType === 2) { // 标点问题
          this.punctuationErrors++;
        }else if (item.errorType === 3) { // 语序问题
           this.yuxu++;
        }else if(item.errorType === 5){ // 语法问题
          this.yufa++;
        }else if(item.errorType === 6){ // 敏感词错误
          this.minganc ++;
        }else if(item.errorType === 101){ // 语法错误
          this.rq ++;
        }else if(item.errorType === 102){ // 语法错误
          this.je ++;
        }else if(itme.errorType === 104){ // 语法错误
          this.cfdy ++;
        }else if(item.errorType === 105){ // 语法错误
          this.xbcw ++;
        }else if(item.errorType === 201){ // 语法错误
           this.ldrcw ++;
        }else if(item.errorType === 202){ // 语法错误
            this.xzqbh ++;
        }
      });
    },
          
    }
}
</script>

<style>
.text {
    font-size: 20px;
}

.item {
    padding: 18px 0;
}

.box-card {
    width: 100%;
}

.el-upload__tip {
    margin-top: -53px;
}
.blue-text {
  color: rgb(28, 27, 27);
  width:"150px"
}
.blue-text1 {
  color: blueviolet;
}
#editor—wrapper {
    border: 1px solid #ccc;
    z-index: 100; /* 按需定义 */
  }
  #toolbar-container { border-bottom: 1px solid #ccc; }
  #editor-container { height: 500px; }
  #editor {
  width: 100%;
  height: 300px;
}
p {
  text-indent: 2em; /* 2em是常用的首行缩进大小，您可以根据需要调整 */
}
</style>

















                    

