import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// 引入elemetn-ui组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import pom from './assets/js/utils/pcOrMobile' // 引入pc与mobile检测文件
import './assets/js/utils/rem' // 引入移动端rem适配
// 引入 Wangeditor 的 CSS
import '@wangeditor/editor/dist/css/style.css';

// 引入 Wangeditor 的 JS
import * as wangEditor from '@wangeditor/editor';

// 将 Wangeditor 挂载到全局
window.wangEditor = wangEditor;
import './assets/global.css';

import axios from "axios";

Vue.use(pom) // 插件增强vue功能

// 从环境变量获取API基础URL
const apiBaseUrl =  'https://ai.zhengjilvsuo.com/api/'; // 如果环境变量未定义，则使用本地开发默认地址
//const apiBaseUrl =  'http://127.0.0.1:9090/api/';
// 创建axios实例并设置基础URL
const instance = axios.create({
  baseURL: apiBaseUrl,
});

// 将axios实例挂载到Vue原型上
Vue.prototype.$axios = instance;
Vue.config.productionTip = false

// 使用element
Vue.use(ElementUI)

new Vue({
  router,
  store,
  render: h => h(App),
  created() {
    document.title = '正己案泉——案如泉涌（录音库-案例库-话术库-文书库-AI复杂资料生成系统）';
  },
}).$mount('#app')
